import {
  PORTFOLIO_CREATE_REQUEST,
  PORTFOLIO_CREATE_SUCCESS,
  PORTFOLIO_CREATE_FAIL,
  PORTFOLIO_CREATE_RESET,

  PORTFOLIO_LIST_REQUEST,
  PORTFOLIO_LIST_SUCCESS,
  PORTFOLIO_LIST_FAIL,

  PERCENT_LIST_REQUEST,
  PERCENT_LIST_SUCCESS,
  PERCENT_LIST_FAIL,

  STOCK_PURCHASE_CREATE_REQUEST,
  STOCK_PURCHASE_CREATE_SUCCESS,
  STOCK_PURCHASE_CREATE_FAIL,
  STOCK_PURCHASE_CREATE_RESET,

  PORT_PRICE_REQUEST,
  PORT_PRICE_SUCCESS,
  PORT_PRICE_FAIL,
  PORT_PRICE_RESET,

  GET_STOCK_POSITIONS_REQUEST,
  GET_STOCK_POSITIONS_SUCCESS,
  GET_STOCK_POSITIONS_FAIL,

  DELETE_STOCK_POSITION_REQUEST,
  DELETE_STOCK_POSITION_SUCCESS,
  DELETE_STOCK_POSITION_FAIL,

  UPDATE_STOCK_PURCHASE_REQUEST,
  UPDATE_STOCK_PURCHASE_SUCCESS,
  UPDATE_STOCK_PURCHASE_FAIL,

} from '../constants/stockConstants';

export const portfolioCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PORTFOLIO_CREATE_REQUEST:
      return { loading: true };
    case PORTFOLIO_CREATE_SUCCESS:
      return { loading: false, success: true, portfolio: action.payload };
    case PORTFOLIO_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PORTFOLIO_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const portfolioListReducer = (state = { portfolios: [] }, action) => {
  switch (action.type) {
    case PORTFOLIO_LIST_REQUEST:
      return { loading: true, portfolios: [] };
    case PORTFOLIO_LIST_SUCCESS:
      return { loading: false, portfolios: action.payload };
    case PORTFOLIO_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const percentListReducer = (state = { percent: [] }, action) => {
  switch (action.type) {
    case PERCENT_LIST_REQUEST:
      return { loading: true, percent: [] };
    case PERCENT_LIST_SUCCESS:
      return { loading: false, percent: action.payload };
    case PERCENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const stockPositionsReducer = (state = { positions: [] }, action) => {
  switch (action.type) {
    case GET_STOCK_POSITIONS_REQUEST:
      return { loading: true, positions: [] };
    case GET_STOCK_POSITIONS_SUCCESS:
      return { loading: false, positions: action.payload };
    case GET_STOCK_POSITIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const stockPurchaseCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case STOCK_PURCHASE_CREATE_REQUEST:
      return { loading: true };
    case STOCK_PURCHASE_CREATE_SUCCESS:
      return { loading: false, success: true };
    case STOCK_PURCHASE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case STOCK_PURCHASE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const portPriceReducer = (state = {}, action) => {
  switch (action.type) {
    case PORT_PRICE_REQUEST:
      return {
        loading: true
      }

    case PORT_PRICE_SUCCESS:
      return {
        loading: false,
        success: true
      }

    case PORT_PRICE_FAIL:
      return {
        loading: false,
        error: action.payload
      }

    case PORT_PRICE_RESET:
      return {}

    default:
      return state
  }
}

export const deleteStockPositionReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_STOCK_POSITION_REQUEST:
      return { loading: true };
    case DELETE_STOCK_POSITION_SUCCESS:
      return { loading: false, success: true };
    case DELETE_STOCK_POSITION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateStockPurchaseReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_STOCK_PURCHASE_REQUEST:
      return { loading: true };
    case UPDATE_STOCK_PURCHASE_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_STOCK_PURCHASE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};





