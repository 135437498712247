import React from 'react'

function DashHeader() {
    return (
        <div>

            <header className="page-header page-header-dark bg-white pb-10">
                <div className="container-xl px-4">
                    <div className="page-header-content pt-4">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-auto mt-4">
                                <h1 className="page-header-title">
                                    <div className="page-header-icon"><i data-feather="activity"></i></div>
                  Finance Dashboard
                </h1>
                                <div className="page-header-subtitle"></div>
                                <div className="page-header-subtitle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </div>
    )
}

export default DashHeader
