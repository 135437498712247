import axios from 'axios';
import {
  PORTFOLIO_CREATE_REQUEST,
  PORTFOLIO_CREATE_SUCCESS,
  PORTFOLIO_CREATE_FAIL,
  PORTFOLIO_CREATE_RESET,

  PORTFOLIO_LIST_REQUEST,
  PORTFOLIO_LIST_SUCCESS,
  PORTFOLIO_LIST_FAIL,

  STOCK_PURCHASE_CREATE_REQUEST,
  STOCK_PURCHASE_CREATE_SUCCESS,
  STOCK_PURCHASE_CREATE_FAIL,
  STOCK_PURCHASE_CREATE_RESET,

  PORT_PRICE_REQUEST,
  PORT_PRICE_SUCCESS,
  PORT_PRICE_FAIL,
  PORT_PRICE_RESET,

  PERCENT_LIST_REQUEST,
  PERCENT_LIST_SUCCESS,
  PERCENT_LIST_FAIL,

  GET_STOCK_POSITIONS_REQUEST,
  GET_STOCK_POSITIONS_SUCCESS,
  GET_STOCK_POSITIONS_FAIL,

  DELETE_STOCK_POSITION_REQUEST,
  DELETE_STOCK_POSITION_SUCCESS,
  DELETE_STOCK_POSITION_FAIL,

  UPDATE_STOCK_PURCHASE_REQUEST,
  UPDATE_STOCK_PURCHASE_SUCCESS,
  UPDATE_STOCK_PURCHASE_FAIL,
} from '../constants/stockConstants';

export const createPortfolio = (name, description, type, value) => async (dispatch, getState) => {
  try {
    dispatch({ type: PORTFOLIO_CREATE_REQUEST });

    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/stocks/portfolios/`,
      { name, description, type, value },
      config
    );

    dispatch({
      type: PORTFOLIO_CREATE_SUCCESS,
      payload: data,
    });

    dispatch({ type: PORTFOLIO_CREATE_RESET });
  } catch (error) {
    dispatch({
      type: PORTFOLIO_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const listPortfolios = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PORTFOLIO_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/stocks/portfolios/`, config);

    dispatch({ type: PORTFOLIO_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PORTFOLIO_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listPercent = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PERCENT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/stocks/portfolio-history-with-percent-change/`, config);

    dispatch({ type: PERCENT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PERCENT_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getStockPositions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_STOCK_POSITIONS_REQUEST });

    const {
      userLogin: { userInfo },
      
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/stocks/stock_positions/`, config);

    dispatch({
      type: GET_STOCK_POSITIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_STOCK_POSITIONS_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const deleteStockPosition = (portfolioId, stockId) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_STOCK_POSITION_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/stocks/portfolios/${portfolioId}/stocks/${stockId}/`, config);

    dispatch({ type: DELETE_STOCK_POSITION_SUCCESS });

    // After deleting, fetch the updated stock positions
    dispatch(getStockPositions());
  } catch (error) {
    dispatch({
      type: DELETE_STOCK_POSITION_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const updateStockPurchase = (portfolioId, stockId, formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_STOCK_PURCHASE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/api/stocks/portfolios/${portfolioId}/stocks/${stockId}/`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_STOCK_PURCHASE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_STOCK_PURCHASE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};




export const createStockPurchase = (portfolioId, ticker, type, quantity, costBasis, datePurchased) => async (dispatch, getState) => {
  try {
    dispatch({ type: STOCK_PURCHASE_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const data = {
      ticker,
      type,
      quantity,
      cost_basis: costBasis,
      date_purchased: datePurchased,
    };

    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/stocks/portfolios/${portfolioId}/stocks/purchase/`, data, config);

    dispatch({
      type: STOCK_PURCHASE_CREATE_SUCCESS,
      payload: response.data,
    });

  } catch (error) {
    dispatch({
      type: STOCK_PURCHASE_CREATE_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const priceUpdate = () => async (dispatch, getState) => {
  try {
      dispatch({
          type: PORT_PRICE_REQUEST
      })

      const {
          userLogin: { userInfo },
      } = getState()

      const config = {
          headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`
          }
      }

      const { data } = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/api/stocks/portfolios/trigger/`,
          {},
          config
      )

      dispatch({
          type: PORT_PRICE_SUCCESS,
          payload: data
      })


  } catch (error) {
      dispatch({
          type: PORT_PRICE_FAIL,
          payload: error.response && error.response.data.detail
              ? error.response.data.detail
              : error.message,
      })
  }
}
