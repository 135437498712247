export const PORTFOLIO_CREATE_REQUEST = 'PORTFOLIO_CREATE_REQUEST';
export const PORTFOLIO_CREATE_SUCCESS = 'PORTFOLIO_CREATE_SUCCESS';
export const PORTFOLIO_CREATE_FAIL = 'PORTFOLIO_CREATE_FAIL';
export const PORTFOLIO_CREATE_RESET = 'PORTFOLIO_CREATE_RESET';

export const PORTFOLIO_LIST_REQUEST = 'PORTFOLIO_LIST_REQUEST';
export const PORTFOLIO_LIST_SUCCESS = 'PORTFOLIO_LIST_SUCCESS';
export const PORTFOLIO_LIST_FAIL = 'PORTFOLIO_LIST_FAIL';

export const STOCK_PURCHASE_CREATE_REQUEST = 'STOCK_PURCHASE_CREATE_REQUEST';
export const STOCK_PURCHASE_CREATE_SUCCESS = 'STOCK_PURCHASE_CREATE_SUCCESS';
export const STOCK_PURCHASE_CREATE_FAIL = 'STOCK_PURCHASE_CREATE_FAIL';
export const STOCK_PURCHASE_CREATE_RESET = 'STOCK_PURCHASE_CREATE_RESET';

export const PORT_PRICE_REQUEST = 'PORT_PRICE_REQUEST'
export const PORT_PRICE_SUCCESS = 'PORT_PRICE_SUCCESS'
export const PORT_PRICE_FAIL = 'PORT_PRICE_FAIL'
export const PORT_PRICE_RESET = 'PORT_PRICE_RESET'

export const GET_STOCK_POSITIONS_REQUEST = 'GET_STOCK_POSITIONS_REQUEST';
export const GET_STOCK_POSITIONS_SUCCESS = 'GET_STOCK_POSITIONS_SUCCESS';
export const GET_STOCK_POSITIONS_FAIL = 'GET_STOCK_POSITIONS_FAIL';

export const DELETE_STOCK_POSITION_REQUEST = 'DELETE_STOCK_POSITION_REQUEST';
export const DELETE_STOCK_POSITION_SUCCESS = 'DELETE_STOCK_POSITION_SUCCESS';
export const DELETE_STOCK_POSITION_FAIL = 'DELETE_STOCK_POSITION_FAIL';

export const UPDATE_STOCK_PURCHASE_REQUEST = 'UPDATE_STOCK_PURCHASE_REQUEST';
export const UPDATE_STOCK_PURCHASE_SUCCESS = 'UPDATE_STOCK_PURCHASE_SUCCESS';
export const UPDATE_STOCK_PURCHASE_FAIL = 'UPDATE_STOCK_PURCHASE_FAIL';

export const PERCENT_LIST_REQUEST = 'PERCENT_LIST_REQUEST';
export const PERCENT_LIST_SUCCESS = 'PERCENT_LIST_SUCCESS';
export const PERCENT_LIST_FAIL = 'PERCENT_LIST_FAIL';

