import React from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";

import { FaBars } from 'react-icons/fa';
import $ from 'jquery'; // Import jQuery

import { useEffect } from 'react';

function Navbar() {

    useEffect(() => {
        // Initialize the dropdown toggle
        $('.dropdown-toggle').on('click', function () {
            $(this).next('.dropdown-menu').slideToggle();
        });
    }, []);
    return (
        <div>
            <nav className="navbar">
                <div className="navbar-left">
                    <h1 className="navbar-title"> OlhoFI</h1>
                </div>
                <div className="navbar-right">
                    <div className="dropdown">
                        <Button
                            className="dropdown-toggle navbar-menu-btn"
                            type="button"
                            id="dropdownMenuButton"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ background: 'black' }}
                        >
                            <FaBars style={{ color: '#fff', fontSize: '1.5rem' }} />
                        </Button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link className="dropdown-item" to="/">
                                Sign In
                            </Link>
                            <a className="dropdown-item" href="/">About</a>
                            <a className="dropdown-item" href="/">Contact</a>
                        </div>
                    </div>


                </div>
            </nav>

        </div>
    );
}

export default Navbar;
