import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { configureStore } from "@reduxjs/toolkit";

import {
    userLoginReducer,
    userRegisterReducer, 
} from './reducers/userReducers'

import { portfolioCreateReducer, portfolioListReducer, stockPurchaseCreateReducer, portPriceReducer, stockPositionsReducer,deleteStockPositionReducer, updateStockPurchaseReducer, percentListReducer } from './reducers/stockReducers';


const reducer = combineReducers({

    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,

    portfolioCreate: portfolioCreateReducer,
    portfolioList: portfolioListReducer,

    stockPurchaseCreate: stockPurchaseCreateReducer,

    portPrice: portPriceReducer,

    stockPositionList: stockPositionsReducer,

    deleteStockPosition: deleteStockPositionReducer,
    updateStockPurchase: updateStockPurchaseReducer,

    percentList: percentListReducer,
    

})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null


const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
    

    
    
}

const middleware = [thunk]
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store
