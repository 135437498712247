import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";


import DashSubHeader from "../components/DashSubHeader";
import DashNav from "../components/DashNav";
import StockPositionsTable from "../components/StockPositionsTable";
import ChartContainer from "../components/ChartContainer";
import PortfolioAdd from "../components/PortfolioAdd";
import DashHeader from "../components/DashHeader";

import { listPortfolios } from '../actions/stockActions';

export default function Dash() {

  const dispatch = useDispatch();
  const history = useNavigate();

  const portPrice = useSelector((state) => state.portPrice);
  const { loading: priceload } = portPrice;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {

    if (userInfo) {
      dispatch(listPortfolios());
    } else {
      history("/");
    }

  }, [history, dispatch, priceload])

  return (

    <section className="dashbpard-sections">
      <div className="container-fluid">
        <DashNav />
        <div className="dashboard-inner row">

          <div className="patient-overview col-lg-12">
            <div className="card mb-10">
              <div className="card-header text-black">Portfolio</div>
              <div>
                <main>
                  <DashHeader />
                 
                  <div className="container-xl px-4 mt-n10">
                    <DashSubHeader />
                    <div className="row">

                      <div className="col-xxl-12">
                        <ChartContainer />
                      </div>

                      {/* <div className="col-xxl-4">
                        <PortfolioAdd />
                      </div> */}

                      <div className="col-xxl-12">
                        <div className="card mb-4">
                          
                          <StockPositionsTable />
                        </div>

                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}
