import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Modal, Form, Button, Row, Col, Table, Alert, Pagination } from "react-bootstrap";

import { createPortfolio, listPortfolios, createStockPurchase, priceUpdate, getStockPositions, listPercent } from '../actions/stockActions';

import {
  PORTFOLIO_CREATE_RESET,
  STOCK_PURCHASE_CREATE_RESET,
} from '../constants/stockConstants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

import GraySquare from "../components/GraySquare";


function DashSubHeader() {

  const dispatch = useDispatch();
  const history = useNavigate();


  const stockPositionList = useSelector((state) => state.stockPositionList);
  const { loading, error, positions } = stockPositionList;

  const stockPercentList = useSelector((state) => state.percentList);
  const { percent } = stockPercentList;



  useEffect(() => {

    if (userInfo) {
      dispatch(listPercent());
    } else {
      history("/");
    }

  }, [history, dispatch])


  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const sumValuesCash = () => {
    let sum = 0;
    if (positions && positions?.length > 0) {
      sum = positions.reduce((accumulator, positions) => {
        if (positions.type == "cash" && !isNaN(parseFloat(positions.value))) {
          return accumulator + parseFloat(positions.value);
        }
        return accumulator;
      }, 0);
    }
    return sum;
  };

  const formattedSum = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sumValuesCash());



  const sumValuesStock = () => {
    let sum = 0;
    if (positions && positions?.length > 0) {
      sum = positions.reduce((accumulator, positions) => {
        if (positions.type == "stock" && !isNaN(parseFloat(positions.value))) {
          return accumulator + parseFloat(positions.value);
        }
        return accumulator;
      }, 0);
    }
    return sum;
  };

  const formattedSumStocks = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sumValuesStock());

  const sumValuesCrypto = () => {
    let sum = 0;
    if (positions && positions?.length > 0) {
      sum = positions.reduce((accumulator, positions) => {
        if (positions.type == "crypto" && !isNaN(parseFloat(positions.value))) {
          return accumulator + parseFloat(positions.value);
        }
        return accumulator;
      }, 0);
    }
    return sum;
  };

  const formattedSumCrypto = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sumValuesCrypto());


  return (
    <div>

      <div className="row">
        <div className="col-xl-4 mb-4">
          {/* Dashboard example card 1 */}
          <a className="card lift h-100" >
            <div className="card-body d-flex justify-content-center flex-column">
              <div className="d-flex align-items-center justify-content-between">
                <div className="me-3">
                  <i className="feather-xl text-primary mb-3" data-feather="package"></i>
                  <h5>Cash</h5>
                  <div className="text-muted small">{positions && positions?.length > 0 ? (
                    <div style={{ height: "3rem" }}>

                      <div style={{ fontFamily: 'Helvetica', fontWeight: 'dark', fontSize: '18px', color: 'black' }}>{formattedSum}</div>

                      <div style={{ fontFamily: 'Helvetica', fontWeight: 'light', fontSize: '10px' }}>Updated {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</div>


                    </div>

                  ) : (
                      <p>No cash positions.</p>
                    )}</div>

                </div>



                <div className="d-flex justify-content-center">
                  {percent && percent[2] && (


                    <>

                      <div className=" light-gray-square me-2">

                        {percent[2].percent_change_1d !== null ? (
                          <p className="m-0 text-center custom-text">
                            <p className="small">1D</p>
                            {percent[2].percent_change_1d > 0 ? (
                              <span className="text-success custom-text">
                                <FontAwesomeIcon icon={faArrowUp} /> {percent[2].percent_change_1d}%
                              </span>
                            ) : percent[2].percent_change_1d < 0 ? (
                              <span className="text-danger custom-text">
                                <FontAwesomeIcon icon={faArrowDown} /> {percent[2].percent_change_1d}%
                              </span>
                            ) : (
                                  "--"
                                )}
                          </p>
                        ) : (
                            <p className="m-0 text-center">&nbsp;</p>
                          )}
                      </div>
                      <div className=" light-gray-square me-2">

                        {percent[2].percent_change_1w !== null ? (
                          <p className="m-0 text-center custom-text">
                            <p className="small">1W</p>
                            {percent[2].percent_change_1w > 0 ? (
                              <span className="text-success custom-text">
                                <FontAwesomeIcon icon={faArrowUp} /> {percent[2].percent_change_1w}%
                              </span>
                            ) : percent[2].percent_change_1w < 0 ? (
                              <span className="text-danger custom-text">
                                <FontAwesomeIcon icon={faArrowDown} /> {percent[2].percent_change_1w}%
                              </span>
                            ) : (
                                  "--"
                                )}
                          </p>
                        ) : (
                            <p className="m-0 text-center">&nbsp;</p>
                          )}
                      </div>
                      <div className="light-gray-square me-2">

                        {percent[2].percent_change_1y !== null ? (
                          <p className="m-0 text-center custom-text">
                            <p className="small">YTD</p>
                            {percent[2].percent_change_1y > 0 ? (
                              <span className="text-success custom-text">
                                <FontAwesomeIcon icon={faArrowUp} /> {percent[2].percent_change_1y}%
                              </span>
                            ) : percent[2].percent_change_1y < 0 ? (
                              <span className="text-danger custom-text">
                                <FontAwesomeIcon icon={faArrowDown} /> {percent[2].percent_change_1y}%
                              </span>
                            ) : (
                                  "--"
                                )}
                          </p>
                        ) : (
                            <p className="m-0 text-center">&nbsp;</p>
                          )}
                      </div>
                    </>
                  )}
                </div>



              </div>
            </div>
          </a>
        </div>
        <div className="col-xl-4 mb-4">
          {/* Dashboard example card 2 */}
          <a className="card lift h-100" >
            <div className="card-body d-flex justify-content-center flex-column">
              <div className="d-flex align-items-center justify-content-between">
                <div className="me-3">
                  <i className="feather-xl text-secondary mb-3" data-feather="book"></i>
                  <h5>Stock</h5>
                  <div className="text-muted small">{positions && positions?.length > 0 ? (
                    <div style={{ height: "3rem" }}>

                      <div style={{ fontFamily: 'Helvetica', fontWeight: 'dark', fontSize: '18px', color: 'black' }}>{formattedSumStocks}</div>

                      <div style={{ fontFamily: 'Helvetica', fontWeight: 'light', fontSize: '10px' }}>Updated {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</div>

                    </div>
                  ) : (
                      <p>No stock positions</p>
                    )}</div>
                </div>

                <div className="d-flex justify-content-center">
                  {percent && percent[0] && (
                    <>
                      <div className=" light-gray-square me-2">
                        {/* Content for the first square block */}
                        {percent[0].percent_change_1d !== null ? (
                          <p className="m-0 text-center custom-text">
                            <p className="small">1D</p>
                            {percent[0].percent_change_1d > 0 ? (
                              <span className="text-success custom-text">
                                <FontAwesomeIcon icon={faArrowUp} /> {percent[0].percent_change_1d}%
                              </span>
                            ) : percent[0].percent_change_1d < 0 ? (
                              <span className="text-danger custom-text">
                                <FontAwesomeIcon icon={faArrowDown} /> {percent[0].percent_change_1d}%
                              </span>
                            ) : (
                                  "--"
                                )}
                          </p>
                        ) : (
                            <p className="m-0 text-center">&nbsp;</p>
                          )}
                      </div>
                      <div className=" light-gray-square me-2">
                        {/* Content for the second square block */}
                        {percent[0].percent_change_1w !== null ? (
                          <p className="m-0 text-center custom-text">
                            <p className="small">1W</p>
                            {percent[0].percent_change_1w > 0 ? (
                              <span className="text-success custom-text">
                                <FontAwesomeIcon icon={faArrowUp} /> {percent[0].percent_change_1w}%
                              </span>
                            ) : percent[0].percent_change_1w < 0 ? (
                              <span className="text-danger custom-text">
                                <FontAwesomeIcon icon={faArrowDown} /> {percent[0].percent_change_1w}%
                              </span>
                            ) : (
                                  "--"
                                )}
                          </p>
                        ) : (
                            <p className="m-0 text-center">&nbsp;</p>
                          )}
                      </div>
                      <div className="light-gray-square me-2">
                        {/* Content for the third square block */}
                        {percent[0].percent_change_1y !== null ? (
                          <p className="m-0 text-center custom-text">
                            <p className="small">YTD</p>
                            {percent[0].percent_change_1y > 0 ? (
                              <span className="text-success custom-text">
                                <FontAwesomeIcon icon={faArrowUp} /> {percent[0].percent_change_1y}%
                              </span>
                            ) : percent[0].percent_change_1y < 0 ? (
                              <span className="text-danger custom-text">
                                <FontAwesomeIcon icon={faArrowDown} /> {percent[0].percent_change_1y}%
                              </span>
                            ) : (
                                  "--"
                                )}
                          </p>
                        ) : (
                            <p className="m-0 text-center">&nbsp;</p>
                          )}
                      </div>
                    </>
                  )}
                </div>

              </div>
            </div>
          </a>
        </div>
        <div className="col-xl-4 mb-4">
          {/* Dashboard example card 3 */}
          <a className="card lift h-100" >
            <div className="card-body d-flex justify-content-center flex-column">
              <div className="d-flex align-items-center justify-content-between">
                <div className="me-3">
                  <i className="feather-xl text-green mb-3" data-feather="layout"></i>
                  <h5>Crypto</h5>
                  <div className="text-muted small">{positions && positions?.length > 0 ? (
                    <div style={{ height: "3rem" }}>

                      <div style={{ fontFamily: 'Helvetica', fontWeight: 'dark', fontSize: '18px', color: 'black' }}>{formattedSumCrypto}</div>

                      <div style={{ fontFamily: 'Helvetica', fontWeight: 'light', fontSize: '10px' }}> Updated {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</div>

                    </div>
                  ) : (
                      <p>No crypto positions</p>
                    )}</div>
                </div>

                <div className="d-flex justify-content-center">
                  {percent && percent[1] && (
                    <>
                      <div className=" light-gray-square me-2">
                        {/* Content for the first square block */}
                        {percent[1].percent_change_1d !== null ? (
                          <p className="m-0 text-center custom-text">
                            <p className="small">1D</p>
                            {percent[1].percent_change_1d > 0 ? (
                              <span className="text-success custom-text">
                                <FontAwesomeIcon icon={faArrowUp} /> {percent[1].percent_change_1d}%
                              </span>
                            ) : percent[1].percent_change_1d < 0 ? (
                              <span className="text-danger custom-text">
                                <FontAwesomeIcon icon={faArrowDown} /> {percent[1].percent_change_1d}%
                              </span>
                            ) : (
                                  "--"
                                )}
                          </p>
                        ) : (
                            <p className="m-0 text-center">&nbsp;</p>
                          )}
                      </div>
                      <div className=" light-gray-square me-2">
                        {/* Content for the second square block */}
                        {percent[1].percent_change_1w !== null ? (
                          <p className="m-0 text-center custom-text">
                            <p className="small">1W</p>
                            {percent[1].percent_change_1w > 0 ? (
                              <span className="text-success custom-text">
                                <FontAwesomeIcon icon={faArrowUp} /> {percent[1].percent_change_1w}%
                              </span>
                            ) : percent[1].percent_change_1w < 0 ? (
                              <span className="text-danger custom-text">
                                <FontAwesomeIcon icon={faArrowDown} /> {percent[1].percent_change_1w}%
                              </span>
                            ) : (
                                  "--"
                                )}
                          </p>
                        ) : (
                            <p className="m-0 text-center">&nbsp;</p>
                          )}
                      </div>
                      <div className="light-gray-square me-2">
                        {/* Content for the third square block */}
                        {percent[1].percent_change_1y !== null ? (
                          <p className="m-0 text-center custom-text">
                            <p className="small">YTD</p>
                            {percent[1].percent_change_1y > 0 ? (
                              <span className="text-success custom-text">
                                <FontAwesomeIcon icon={faArrowUp} /> {percent[1].percent_change_1y}%
                              </span>
                            ) : percent[1].percent_change_1y < 0 ? (
                              <span className="text-danger custom-text">
                                <FontAwesomeIcon icon={faArrowDown} /> {percent[1].percent_change_1y}%
                              </span>
                            ) : (
                                  "--"
                                )}
                          </p>
                        ) : (
                            <p className="m-0 text-center">&nbsp;</p>
                          )}
                      </div>
                    </>
                  )}
                </div>

              </div>
            </div>
          </a>
        </div>
      </div>

    </div>
  )
}

export default DashSubHeader
