import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Modal, Form, Button, Row, Col, Table, Alert, Badge, Pagination } from "react-bootstrap";

import DashSubHeader from "../components/DashSubHeader";
import DashNav from "../components/DashNav";
import StockPositionsTable from "../components/StockPositionsTable";
import ChartContainer from "../components/ChartContainer";

import { createPortfolio, listPortfolios, createStockPurchase, priceUpdate, getStockPositions } from '../actions/stockActions';

import {
  PORTFOLIO_CREATE_RESET,
  STOCK_PURCHASE_CREATE_RESET,
} from '../constants/stockConstants';

export default function Test() {

  // Constants
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [value, setValue] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loadingmodal, setLoadingmodal] = useState(false);
  const [show, setShow] = useState(false);
  const [portfolioId, setPortfolioId] = useState('');

  //DELETE

  const [showModal, setShowModal] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [costBasis, setCostBasis] = useState(0);
  const [datePurchased, setDatePurchased] = useState('');
  const [ticker, setTicker] = useState('');

  //DELETE


  const dispatch = useDispatch();
  const history = useNavigate();

  // States

  //Delete

  const portfolioCreate = useSelector((state) => state.portfolioCreate);
  const { loading, success, error } = portfolioCreate;


  const stockPurchaseList = useSelector((state) => state.portfolioList);
  const { portfolios } = stockPurchaseList;

  //Delete

  const stockPurchaseCreate = useSelector((state) => state.stockPurchaseCreate);
  const { loading3, success3, error3 } = stockPurchaseCreate;

  const portPrice = useSelector((state) => state.portPrice);
  const { loading: priceload } = portPrice;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;


  // Use Effects

  

  useEffect(() => {

    if (userInfo) {
      dispatch(listPortfolios());
    } else {
      history("/");
    }
    
  }, [history, dispatch, priceload])

  useEffect(() => {
    if (success) {
      setShowSuccessMessage(true);
      setName('');
      setDescription('');
      setType('');
      setValue('');
      dispatch({ type: PORTFOLIO_CREATE_RESET });
      handleClose();
      dispatch(listPortfolios());

      // Hide the success message after a certain duration
      const timeout = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);

      // Clear the timeout on component unmount
      return () => clearTimeout(timeout);
    }
  }, [dispatch, success]);

  useEffect(() => {
    if (success3) {
      handleModalClose();
      dispatch({ type: STOCK_PURCHASE_CREATE_RESET });
      
    }
  }, [dispatch, success3]);

  // Sumbit handlers

  const submitHandler = async (e) => {
    e.preventDefault();
  
    await dispatch(createPortfolio(name, description, type, value));
  
    await dispatch(priceUpdate());
  };


  //Delete
  

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    await dispatch(createStockPurchase(portfolioId, ticker, type, quantity, costBasis, datePurchased));
  
    setTicker('');
    setType('');
    setQuantity('');
    setCostBasis('');
    setDatePurchased('');
  
    handleCloseModal();
  
    await dispatch(priceUpdate());
  };
  

  // Modal Handlers

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleModalShow = (id) => {
    setPortfolioId(id);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setQuantity(0);
    setCostBasis(0);
    setDatePurchased('');
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Pagination

  const pageSize = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPagePortfolios = portfolios?.slice(startIndex, endIndex);
  const totalPages = Math.ceil((portfolios?.length || 0) / pageSize);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  // Sum values

  const sumValues = () => {
    let sum = 0;
    if (portfolios && portfolios?.length > 0) {
      sum = portfolios.reduce((accumulator, portfolio) => {
        if (portfolio.value && !isNaN(parseFloat(portfolio.value))) {
          return accumulator + parseFloat(portfolio.value);
        }
        return accumulator;
      }, 0);
    }
    return sum;
  };

  const formattedSum = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sumValues());


  // Table Logic

  const stockPositionList = useSelector((state) => state.stockPositionList);
  const { loading4, error4, positions } = stockPositionList;

  useEffect(() => {
    dispatch(getStockPositions());
  }, [dispatch, portfolios]);



  return (


           

                      <div className="col-xxl-4">
                        <div className="row">
                          <div className="col-xl-6 col-xxl-12">
                            {/* Team members / people dashboard card example */}
                            <div className="card mb-4 d-flex flex-column h-80">
                              <div className="card-header d-flex justify-content-between align-items-center">
                                <div>Portfolio</div>
                                <Button variant="primary btn-sm" onClick={handleShow} className="rounded-pill">
                                  Create Portfolio
                                                                </Button>
                              </div>

                              <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                  <Modal.Title>Create Portfolio</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <Form onSubmit={submitHandler}>
                                    <Form.Group controlId="name">
                                      <Form.Label>Name</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter portfolio name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                      />
                                    </Form.Group>

                                    <Form.Group controlId="description">
                                      <Form.Label>Description</Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter portfolio description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                      />
                                    </Form.Group>

                                    <Form.Group controlId="type">
                                      <Form.Label>Type</Form.Label>
                                      <Form.Control
                                        as="select"
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}
                                      >
                                        <option value="">Select type</option>
                                        <option value="cash">Cash</option>
                                        <option value="stock">Stock</option>
                                        <option value="crypto">Crypto</option>
                                        <option value="SPAC">SPAC</option>
                                        <option value="Warrant">Warrant</option>
                                      </Form.Control>
                                    </Form.Group>

                                    <Button type="submit" disabled={loading}>
                                      {loading ? 'Creating...' : 'Create'}
                                    </Button>
                                  </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant="secondary " onClick={handleClose}>
                                    Close
                                                                        </Button>
                                </Modal.Footer>
                              </Modal>

                              <div className="card-body d-flex flex-column" style={{ minHeight: "auto" }}>
                                {error && <Alert variant="danger">Make sure to input all the fields</Alert>}
                                {showSuccessMessage && <Alert variant="success">Portfolio created successfully!</Alert>}
                                <div className="flex-grow-1" style={{ maxHeight: "calc(100vh - 420px)", overflowY: "auto" }}>
                                  {portfolios && portfolios?.length > 0 ? (
                                    <div style={{ height: "20rem" }}>
                                      <h5>
                                        Total Value:
                                        
                                      <div className="total-value-container">
                                          <span className="total-value">{formattedSum}</span>
                                        </div>
                                      </h5>


                                      <Table hover>
                                        <tbody>
                                          {currentPagePortfolios
                                            .sort((a, b) => (b.value || 0) - (a.value || 0)) // Sort portfolios by value in descending order
                                            .filter((portfolio) => portfolio.value) // Filter portfolios with values
                                            .map((portfolio) => (
                                              <tr key={portfolio.id}>
                                                <td>{portfolio.name}</td>
                                                <td>
                                                  <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(portfolio.value)}</span>
                                                </td>
                                                <td>
                                                  <Button
                                                    onClick={() => handleModalShow(portfolio.id)}
                                                    variant="secondary btn-sm"
                                                    className="rounded-pill"
                                                  >
                                                    Add Positions</Button>

                                                  <Modal show={showModal} onHide={handleModalClose}>
                                                    <Modal.Header closeButton>
                                                      <Modal.Title>Add Stock Purchase</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                      <Form onSubmit={handleFormSubmit}>

                                                        <Form.Group controlId="ticker">
                                                          <Form.Label>Ticker</Form.Label>
                                                          <Form.Control
                                                            type="text"
                                                            value={ticker}
                                                            onChange={(e) => setTicker(e.target.value)}
                                                          />
                                                        </Form.Group>
                                                        <Form.Group controlId="type">
                                                          <Form.Label>Type</Form.Label>
                                                          <Form.Control
                                                            as="select"
                                                            value={type}
                                                            onChange={(e) => setType(e.target.value)}
                                                          >
                                                            <option value="">Select type</option>
                                                            <option value="cash">Cash</option>
                                                            <option value="stock">Stock</option>
                                                            <option value="crypto">Crypto</option>
                                                            <option value="spac">SPAC</option>
                                                            <option value="warrant">Warrant</option>
                                                          </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group controlId="quantity">
                                                          <Form.Label>Quantity</Form.Label>
                                                          <Form.Control
                                                            type="number"
                                                            placeholder="Number of shares"
                                                            value={quantity}
                                                            onChange={(e) => setQuantity(e.target.value)}
                                                          />
                                                        </Form.Group>
                                                        <Form.Group controlId="costBasis">
                                                          <Form.Label>Cost Basis</Form.Label>
                                                          <Form.Control
                                                            type="number"
                                                            placeholder="Purchase price"
                                                            value={costBasis}
                                                            onChange={(e) => setCostBasis(e.target.value)}
                                                          />
                                                        </Form.Group>
                                                        <Form.Group controlId="datePurchased">
                                                          <Form.Label>Date Purchased</Form.Label>
                                                          <Form.Control
                                                            type="date"
                                                            value={datePurchased}
                                                            onChange={(e) => setDatePurchased(e.target.value)}
                                                          />
                                                        </Form.Group>
                                                        <Button variant="primary" type="submit" disabled={loading3}>
                                                          {loading3 ? 'Submitting' : 'Submit'}
                                                        </Button>
                                                        <Modal.Footer>
                                                          <Button variant="secondary" onClick={handleModalClose}>
                                                            Close</Button>
                                                        </Modal.Footer>
                                                      </Form>
                                                    </Modal.Body>
                                                  </Modal>
                                                </td>
                                              </tr>
                                            ))}
                                          {currentPagePortfolios
                                            .filter((portfolio) => !portfolio.value) // Filter portfolios without values
                                            .map((portfolio) => (
                                              <tr key={portfolio.id}>
                                                <td>{portfolio.name}</td>
                                                <td>
                                                  <Badge variant="info">No positions</Badge>
                                                </td>
                                                <td>
                                                  <Button
                                                    onClick={() => handleModalShow(portfolio.id)}
                                                    variant="secondary btn-sm"
                                                    className="rounded-pill"
                                                  >
                                                    Add Positions</Button>
                                                    <Modal show={showModal} onHide={handleModalClose}>
                                                    <Modal.Header closeButton>
                                                      <Modal.Title>Add Stock Purchase</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                      <Form onSubmit={handleFormSubmit}>

                                                        <Form.Group controlId="ticker">
                                                          <Form.Label>Ticker</Form.Label>
                                                          <Form.Control
                                                            type="text"
                                                            value={ticker}
                                                            onChange={(e) => setTicker(e.target.value)}
                                                          />
                                                        </Form.Group>
                                                        <Form.Group controlId="type">
                                                          <Form.Label>Type</Form.Label>
                                                          <Form.Control
                                                            as="select"
                                                            value={type}
                                                            onChange={(e) => setType(e.target.value)}
                                                          >
                                                            <option value="">Select type</option>
                                                            <option value="cash">Cash</option>
                                                            <option value="stock">Stock</option>
                                                            <option value="crypto">Crypto</option>
                                                            <option value="spac">SPAC</option>
                                                            <option value="warrant">Warrant</option>
                                                          </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group controlId="quantity">
                                                          <Form.Label>Quantity</Form.Label>
                                                          <Form.Control
                                                            type="number"
                                                            placeholder="Number of shares"
                                                            value={quantity}
                                                            onChange={(e) => setQuantity(e.target.value)}
                                                          />
                                                        </Form.Group>
                                                        <Form.Group controlId="costBasis">
                                                          <Form.Label>Cost Basis</Form.Label>
                                                          <Form.Control
                                                            type="number"
                                                            placeholder="Purchase price"
                                                            value={costBasis}
                                                            onChange={(e) => setCostBasis(e.target.value)}
                                                          />
                                                        </Form.Group>
                                                        <Form.Group controlId="datePurchased">
                                                          <Form.Label>Date Purchased</Form.Label>
                                                          <Form.Control
                                                            type="date"
                                                            value={datePurchased}
                                                            onChange={(e) => setDatePurchased(e.target.value)}
                                                          />
                                                        </Form.Group>
                                                        <Button variant="primary" type="submit" disabled={loading3}>
                                                          {loading3 ? 'Submitting' : 'Submit'}
                                                        </Button>
                                                        <Modal.Footer>
                                                          <Button variant="secondary" onClick={handleModalClose}>
                                                            Close</Button>
                                                        </Modal.Footer>
                                                      </Form>
                                                    </Modal.Body>
                                                  </Modal>
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                      </Table>
                                    </div>
                                  ) : (
                                      <p>No portfolios found.</p>
                                    )}
                                </div>
                              </div>

                              <div className="fixed-bottom" style={{ position: "absolute", bottom: "0", left: "0", right: "0" }}>
                                <div className="card-footer d-flex justify-content-end">
                                  <ul className="pagination">
                                    {Array.from({ length: totalPages }).map((_, i) => (
                                      <li
                                        key={i + 1}
                                        className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}
                                      >
                                        <button
                                          className="page-link"
                                          onClick={() => handlePageChange(i + 1)}
                                          disabled={currentPage === i + 1}
                                        >
                                          {i + 1}
                                        </button>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>

                            </div>
                          </div>
                        
                        </div>
                      </div>

             

  );
}
