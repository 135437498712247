import { Container } from 'react-bootstrap'

import {
  HashRouter as Router,
  Switch,
  Route,
  Routes,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";



// import './App.css';

import Login from './screens/LoginScreen';
import Dash from './screens/DashScreen'; 
import Test from './screens/TestScreen';

function App() {
  return (

    <Router>
     
      <main >
        <Container>
          <Routes>

          <Route path='' element={<Login />} exact />
          <Route path='/dash' element={<Dash />} exact />
          <Route path='/test' element={<Test />} exact />

          </Routes>
        </Container>
      </main>
      
    </Router>
    
  );
}

export default App;
