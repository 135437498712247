import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Badge,
  IconButton,
} from '@mui/material';
import { Modal, Form, Button, Row, Col, Alert, Pagination } from "react-bootstrap";
import moment from 'moment';
import { Edit, Delete } from '@mui/icons-material';
import { deleteStockPosition, priceUpdate, updateStockPurchase } from '../actions/stockActions';

import { DataGrid } from '@mui/x-data-grid';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';


const StockPositionsTable = () => {


  const customFontStyle = {
    fontFamily: 'IBM Plex Sans, sans-serif', // Replace 'Your-Preferred-Font' with the font you want to use
  };

  const dispatch = useDispatch();
  const stockPositionList = useSelector((state) => state.stockPositionList);
  const portfolioList = useSelector((state) => state.portfolioList);
  const { loading, error, positions } = stockPositionList;
  const { portfolios } = portfolioList;

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState({
    ticker: '',
    type: '',
    quantity: '',
    cost_basis: '',
    date_purchased: '',
  });
  const [selectedPosition, setSelectedPosition] = useState(null);

  const handleOpenEditModal = (position) => {
    setSelectedPosition(position);
    setEditFormData({
      ticker: position.ticker,
      type: position.type,
      quantity: position.quantity,
      cost_basis: position.cost_basis,
      date_purchased: position.date_purchased,
    });
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  const handleEditFormChange = (e) => {
    setEditFormData({ ...editFormData, [e.target.name]: e.target.value });
  };

  const handleEditFormSubmit = async (e) => {
    e.preventDefault();
    if (selectedPosition) {
      await dispatch(updateStockPurchase(selectedPosition.portfolio, selectedPosition.id, editFormData));
      await dispatch(priceUpdate());
      handleCloseEditModal();
    }
  };


  const calculatePositionValue = (position) => {
    return position.quantity * position.recent_price;
  };

  const calculatePositionValueDiff = (position) => {
    if (position && position.recent_price && position.cost_basis) {
      return position.quantity * (position.recent_price - position.cost_basis);
    }
    return 0;
  };


  const formatValue = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  const handleDeletePosition = async (portfolioId, stockId) => {
    try {
      await dispatch(deleteStockPosition(portfolioId, stockId));
      await dispatch(priceUpdate());
    } catch (error) {
      // Handle any errors
    }
  };



  const columns = [
    {
      field: 'ticker',
      headerName: 'Ticker',
      width: 150,
      valueFormatter: (params) => {
        return params.value === 'CASH' ? 'N/A' : params.value; // Replace 'CASH' with a coin stack emoji
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 170,
      valueFormatter: (params) => {
        // Capitalize the first letter of the value
        const capitalizedValue = params.value.charAt(0).toUpperCase() + params.value.slice(1).toLowerCase();
        return capitalizedValue;
      },
    },

    { field: 'portfolio_name', headerName: 'Portfolio', width: 155 },
    {
      field: 'value',
      headerName: 'Value',
      sortComparator: (a, b) => b - a,
      width: 200,
      valueFormatter: (params) =>
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(params.value),
    },

    {
      field: 'recent_price',
      headerName: 'Recent Price',
      sortComparator: (a, b) => b - a,
      width: 200,
      valueFormatter: (params) => {
        if (params.value === null) {
          return 'N/A'; // Or any other representation for null
        }
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(params.value);
      },
    },

    {
      field: 'date_purchased',
      headerName: 'Date Purchased',
      width: 190,
      valueFormatter: (params) => moment(params.value).fromNow(true),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 180,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="Edit"
            onClick={() => handleOpenEditModal(params.row)}
          >
            <Edit />
          </IconButton>
          <IconButton
            aria-label="Delete"
            onClick={() => {
              const { portfolio, id } = params;
              console.log(params.row.portfolio); // Access the portfolio ID
              console.log(id); // Access the stock position ID
              handleDeletePosition(params.row.portfolio, id); // Pass both IDs to your delete function
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  //Accordian for future use

  const updatedColumns = columns.map((column) => {
    if (column.field === 'age') {
      // Set hide to false to unhide the column
      return { ...column, hide: false };
    }
    return column;
  });

  const groupedPositions = {};
  if (positions && positions.length > 0) {
    positions.reduce((groups, position) => {
      const portfolioName = position.portfolio_name;

      if (!groups[portfolioName]) {
        groups[portfolioName] = [];
      }

      groups[portfolioName].push(position);
      return groups;
    }, groupedPositions);
  }

  const sortedPortfolioNames = Object.keys(groupedPositions).sort();

  const portfolioSummaries = sortedPortfolioNames.map((portfolioName) => {
    const portfolioPositions = groupedPositions[portfolioName];
    const sumValue = portfolioPositions.reduce(
      (total, position) => total + parseInt(position.value, 10),
      0
    );
    return { name: portfolioName, totalValue: sumValue };
  });

  // Sort the portfolio summaries in descending order by total value
  portfolioSummaries.sort((a, b) => b.totalValue - a.totalValue);


  return (
    <div className="card-body py-5">
      <div className="d-flex flex-column justify-content-center">
        <div className="card-header d-flex justify-content-between align-items-center">
          <div>Stock Positions</div>
        </div>
        {loading ? (
          <p>Loading stock positions...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : positions?.length > 0 ? (
          <div>

            <div style={{ width: '100%' }}>
              <DataGrid
                rows={positions}
                columns={columns}
                pageSize={10}
                checkboxSelection
                pagination
                disableColumnMenu
                pageSizeOptions={[5, 10]}
                disableSelectionOnClick
              />
            </div>

          </div>

        ) : (
          <p>No stock positions found.</p>
        )}
        <Modal show={editModalOpen} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Stock Purchase</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleEditFormSubmit}>
              <Form.Group controlId="editFormTicker">
                <Form.Label>Ticker</Form.Label>
                <Form.Control
                  type="text"
                  name="ticker"
                  value={editFormData.ticker}
                  onChange={handleEditFormChange}
                />
              </Form.Group>
              <Form.Group controlId="editFormType">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  type="text"
                  name="type"
                  value={editFormData.type}
                  onChange={handleEditFormChange}
                />
              </Form.Group>
              <Form.Group controlId="editFormQuantity">
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  type="text"
                  name="quantity"
                  value={editFormData.quantity}
                  onChange={handleEditFormChange}
                />
              </Form.Group>
              <Form.Group controlId="editFormCostBasis">
                <Form.Label>Cost Basis</Form.Label>
                <Form.Control
                  type="text"
                  name="cost_basis"
                  value={editFormData.cost_basis}
                  onChange={handleEditFormChange}
                />
              </Form.Group>
              <Form.Group controlId="editFormDatePurchased">
                <Form.Label>Date Purchased</Form.Label>
                <Form.Control
                  type="date"
                  name="date_purchased"
                  value={editFormData.date_purchased}
                  onChange={handleEditFormChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );



};

export default StockPositionsTable;
