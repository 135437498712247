import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import 'chartjs-adapter-date-fns';
import 'chartjs-adapter-luxon';

const ChartComponent = () => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const portfolioList = useSelector((state) => state.portfolioList);
  const { portfolios } = portfolioList;

  const [totalValue, setTotalValue] = useState(0); // Declare and initialize totalValue state

  useEffect(() => {
    const initializeChart = () => {
      if (chartRef.current && portfolios && portfolios.length > 0) {
        if (chartInstance) {
          chartInstance.destroy();
        }

        const sortedPortfolios = [...portfolios].sort((a, b) => b.value - a.value);
        const calculatedTotalValue = sortedPortfolios.reduce((sum, portfolio) => sum + Number(portfolio.value), 0); // Convert portfolio.value to a number
        setTotalValue(calculatedTotalValue); // Update the totalValue state

        const chartData = {
          labels: sortedPortfolios.map((portfolio) => portfolio.name),
          datasets: [
            {
              data: sortedPortfolios.map((portfolio) => Number(portfolio.value)), // Convert portfolio.value to a number
              backgroundColor: [
                '#ff6384',
                '#36a2eb',
                '#ffcd56',
                '#4bc0c0',
                '#9966ff',
                '#ff9f40',
                '#cc65fe',
              ],
              borderColor: 'rgba(255, 255, 255, 1)',
              borderWidth: 1,
              spacing: 8,
            },
          ],
        };

        const chartOptions = {
          cutout: '85%',
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: () => 'Total Value',
                label: (context) => {
                  const portfolioIndex = context.dataIndex;
                  const portfolio = sortedPortfolios[portfolioIndex];
                  const value = context.dataset.data[portfolioIndex];
                  return `${portfolio.name}: ${value.toLocaleString()}`;
                },
              },
              displayColors: false,
              bodyAlign: 'center',
              titleAlign: 'center',
              bodyFont: {
                weight: 'bold',
              },
            },
            datalabels: {
              display: false, // Hide the value labels on each slice
            },
          },
          aspectRatio: 1,
          maintainAspectRatio: false,
        };

        const ctx = chartRef.current.getContext('2d');
        const newChartInstance = new Chart(ctx, {
          type: 'doughnut',
          data: chartData,
          options: chartOptions,
        });

        chartRef.current.parentElement.style.width = '100%';
        chartRef.current.parentElement.style.height = '100%';

        setChartInstance(newChartInstance);

        return newChartInstance;
      }
      return null;
    };

    initializeChart();
  }, [portfolios]);

  return (
    <div className="chart-area mb-4 mb-lg-0" style={{ width: '20rem', height: '20rem', position: 'relative' }}>
      <canvas ref={chartRef} />
      {portfolios && portfolios.length > 0 && (
        <div className="total-value" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
          <div style={{ fontFamily: 'Helvetica', fontWeight: 'light', fontSize: '24px' }}>{new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</div>
          <div style={{ fontFamily: 'Helvetica', fontWeight: 'light', fontSize: '14px' }}>Total Value:</div>
          <div style={{ fontFamily: 'Helvetica', fontWeight: 'light', fontSize: '24px' }}>${totalValue.toLocaleString()}</div>
        </div>
      )}
    </div>
  );
};

export default ChartComponent;
