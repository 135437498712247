import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Row, Col, Table, Alert, Badge, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import ChartComponent from "../components/ChartComponent";
import PortfolioDetail from "../components/PortfolioDetail"

import { createPortfolio, listPortfolios, createStockPurchase, priceUpdate, getStockPositions } from '../actions/stockActions';


export default function ChartContainer() {

    const dispatch = useDispatch();




    const priceHandler = async (e) => {
        e.preventDefault();

        await dispatch(priceUpdate());
    };



    return (

        <section className="dashbpard-sections">

            <div>

                <div className="card mb-4 d-flex flex-column h-95">
                    <div className="card-header border-bottom d-flex justify-content-between align-items-center">
                        {/* Dashboard card navigation */}
                        <ul className="nav nav-tabs card-header-tabs" id="dashboardNav" role="tablist">
                            <li className="nav-item me-1">
                                <a className="nav-link active" id="overview-pill" href="#overview" data-bs-toggle="tab" role="tab" aria-controls="overview" aria-selected="true">Overview</a>
                            </li>
                            <li className="nav-item">
                                {/* <a className="nav-link" id="activities-pill" href="#activities" data-bs-toggle="tab" role="tab" aria-controls="activities" aria-selected="false">Activities</a> */}
                            </li>
                        </ul>
                        <Button variant="cyan rounded-pill btn-sm" onClick={priceHandler}>Portfolio Value</Button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            {/* Left Half */}
                            <div className="col-md-6">
                                <div className="tab-content" id="dashboardNavContent">
                                    {/* Dashboard Tab Pane 1 */}
                                    <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-pill">
                                        <div className="chart-area mb-4 mb-lg-0" style={{ height: "27.5rem" }}>
                                            <div style={{ position: "relative", height: "100%" }}>
                                                <canvas id="myAreaChart" width="100%" height="100%" style={{ display: "none" }} />
                                                {/* <canvas ref={chartRef} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} /> */}
                                                <ChartComponent></ChartComponent>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Dashboard Tab Pane 2 */}
                                </div>
                            </div>

                            {/* Right Half */}
                            <div className="col-md-6">
                                <PortfolioDetail/>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </section>

    );
}
